import logo from './logo.svg';
import './App.css';
import Background from './background.jpg'

function App() {
  return (
    <div className="App-header">
      <div style={{backgroundSize: 'cover', backgroundposition: 'center', backgroundImage: `url(${Background})`, position: 'absolute', top: 0, left: 0, right: 0, bottom: 0}}/>

        <div style={{borderColor: 'blue', border: '2px solid redx', background: 'white', opacity: '0.5', width: '900px', height: '300px', position: 'absolute', alignSelf: 'center'}}>
          
        </div>
        <div className="App" style={{opacity: '0.9', width: '1000px', height: '100%', alignItems: 'center', justifyContent: 'center'}}>
          <p style={{color: 'white', fontSize: 25}}>
            This website is under construction but...
          </p>
          <p style={{color: '#047FB8', fontSize: 35}}>
            We'll be back very soon
          </p>
          <p style={{color: 'white', fontSize: 25}}>
            and you still can make reservation for a ride using the phone number
          </p>
        </div>
          

    </div>
  );
}

export default App;